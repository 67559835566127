var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex",attrs:{"id":"wrapper"}},[_c('div',{staticClass:"bg-light border-right",attrs:{"id":"sidebar-wrapper"}},[_c('div',{staticClass:"list-group list-group-flush"},[_c('router-link',{class:[
            _vm.currentRoute.includes('/admin/dashboard/allsites')
              ? 'link-active'
              : 'link-normal',
            'text-left',
            'p-3',
            'pl-3',
            'custom-link' ],attrs:{"to":"/admin/dashboard/allsites"}},[_vm._v("All Sites")]),_c('router-link',{class:[
            _vm.currentRoute.includes('/admin/dashboard/mayuge')
              ? 'link-active'
              : 'link-normal',
            'text-left',
            'p-3',
            'pl-3',
            'custom-link' ],attrs:{"to":"/admin/dashboard/mayuge"}},[_vm._v("Mayuge")]),_c('router-link',{class:[
            _vm.currentRoute.includes('/admin/dashboard/gynecologist')
              ? 'link-active'
              : 'link-normal',
            'text-left',
            'p-3',
            'pl-3',
            'custom-link' ],attrs:{"to":"/admin/dashboard/gynecologist"}},[_vm._v("Gynecologists")]),_c('router-link',{class:[
            _vm.currentRoute.includes('/admin/dashboard/uci')
              ? 'link-active'
              : 'link-normal',
            'text-left',
            'p-3',
            'pl-3',
            'custom-link' ],attrs:{"to":"/admin/dashboard/uci"}},[_vm._v("UCI")]),_c('router-link',{class:[
            _vm.currentRoute.includes('/admin/dashboard/mbarara')
              ? 'link-active'
              : 'link-normal',
            'text-left',
            'p-3',
            'pl-3',
            'custom-link' ],attrs:{"to":"/admin/dashboard/mbarara"}},[_vm._v("Mbarara")]),_c('router-link',{class:[
            _vm.currentRoute.includes('/admin/dashboard/jinja')
              ? 'link-active'
              : 'link-normal',
            'text-left',
            'p-3',
            'pl-3',
            'custom-link' ],attrs:{"to":"/admin/dashboard/jinja"}},[_vm._v("Jinja")]),_c('router-link',{class:[
            _vm.currentRoute.includes('/admin/dashboard/arua')
              ? 'link-active'
              : 'link-normal',
            'text-left',
            'p-3',
            'pl-3',
            'custom-link' ],attrs:{"to":"/admin/dashboard/arua"}},[_vm._v("Arua")])],1)]),_c('div',{attrs:{"id":"page-content-wrapper"}},[_vm._m(0),_c('div',{staticClass:"p-3 content-view container"},[_c('router-view')],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"navbar navbar-expand-lg navbar-secondary p-1 bg-light border-bottom"},[_c('button',{staticClass:"btn btn-secondary ml-2",staticStyle:{"font-size":"0.9rem"},attrs:{"id":"menu-toggle"}},[_vm._v(" Toggle Dashboard ")])])}]

export { render, staticRenderFns }